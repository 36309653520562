/**
 * This utility allow to encrypt and decrypt data, usind lib crypto,
 * and AES cypher, for generating two way hash.
 */
import * as crypto from 'crypto';

const IV_SIZE = 16;
const KEY_CIPHER = 'K60Dyz7e0W2mv8Pb6M3JMA7$$nwC9WFA';

const CipherUtil = {

    /**
     * Encrypts text with AES cypher, and includes a salt.
     * @param {*} stringToCipher
     * @returns String HashedString
     */
    generateTwoWayCrypt(stringToCipher) {
        const iv = crypto.randomBytes(IV_SIZE);
        const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(KEY_CIPHER), iv);
        let encrypted = cipher.update(stringToCipher);
        encrypted = Buffer.concat([encrypted, cipher.final()]);
        return this.base64Encode(`${iv.toString('hex')}:${encrypted.toString('hex')}`);
    },

    /**
     * Decrypts hash text.
     * @param {*} cryptedText
     * @returns String decrypted Text
     */
    decryptTwoWayText(cryptedText) {
        const decode64Text = this.base64Decode(cryptedText);
        const textParts = decode64Text.split(':');
        const iv = Buffer.from(textParts.shift(), 'hex');
        const encryptedText = Buffer.from(textParts.join(':'), 'hex');
        const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(KEY_CIPHER), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    },

    /**
     * Allow to encode in Base64 a text.
     * @param {*} textToEncode
     * @returns String decrypted Text
     */
    base64Encode(textToEncode) {
        return Buffer.from(textToEncode).toString('base64');
    },

    /**
     * Allow to decode base64 text
     * @param {*} cryptedText
     * @returns String decrypted Text
     */
    base64Decode(textToDecode) {
        return Buffer.from(textToDecode, 'base64').toString('ascii');
    },

};

export default CipherUtil;
