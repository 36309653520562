import { BehaviorSubject } from 'rxjs';
import Router from 'next/router';
import CipherUtil from 'admin/src/utilities/CipherUtil';

const userSubject = new BehaviorSubject(typeof window !== 'undefined' && localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || ''));

const login = async(username: string, password: string) => {
    const token = CipherUtil.generateTwoWayCrypt(`${username}:${password}`);
    const auth = await fetch('/api/authenticate',
        {
            method: 'post',
            body: JSON.stringify({ token }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json());

    if (!(auth?.success)) return auth?.data || ''; 
    
    if (auth?.data){
        const user = auth.data;
        // publish user to subscribers and store in local storage to stay logged in between page refreshes
        userSubject.next(user);
        localStorage.setItem('user', JSON.stringify(user));
        
        return user;
    }

    return null;
};

const logout = () => {
    // remove user from local storage, publish null to user subscribers and redirect to login page
    localStorage.removeItem('user');
    userSubject.next(null);
    Router.push('/login');
};

export const userService = {
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value; },
    login,
    logout,
};