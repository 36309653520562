import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { userService } from 'admin/src/service/userService';

export const useAuthorized = () => {
    const router = useRouter();
    const [user, setUser] = useState(null as any);
    const [authorized, setAuthorized] = useState(false);

    useEffect(() => {
        // on initial load - run auth check 
        authCheck(router.asPath);

        // on route change start - check if user exists  
        const hideContent = () => setAuthorized(userService.userValue?.token?.length > 0);
        router.events.on('routeChangeStart', hideContent);

        // on route change complete - run auth check 
        router.events.on('routeChangeComplete', authCheck);

        // unsubscribe from events in useEffect return function
        return () => {
            router.events.off('routeChangeStart', hideContent);
            router.events.off('routeChangeComplete', authCheck);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function authCheck(url: string) {
        // redirect to login page if accessing a private page and not logged in 
        setUser(userService.userValue);
        const privatePaths = ['/admin'];
        const path = url.split('#')[0];

        if (!userService.userValue) { setAuthorized(false); }
        else { setAuthorized(true); }

        if (!userService.userValue && privatePaths.includes(path)) {
            router.push({
                pathname: '/login',
                query: { returnUrl: router.asPath }
            });
        }
    }

    return [
        authorized,
        user,
    ];
};