/* eslint-disable @next/next/no-html-link-for-pages */
import { AppContext } from "context/appContext";
import { DeviceEnum } from "models/enums";
import Link from "next/link";
import { useContext, useState } from "react";
import { cloudinaryLoaderImage, defaultLoader } from "utils/cloudinaryUtils";
import useResize from "components/Hooks/useResize";
import dynamic from "next/dynamic";
import { useAuthorized } from "admin/src/hooks/useAuthorized";

const Image = dynamic(() => import("next/image"));

const CreateHeaderButtons = () => {
    return <></>; // TODO: implement header buttons
};

const Navigation = ({
    menuItems,
    headerSettings,
    themeInformation,
    thirdPartyIntegration,
}: any) => {
    const { whichDevice } = useResize();
    const { openFilterInMobile } = useContext(AppContext);
    const isMobileDevice = whichDevice === DeviceEnum.MOBILE;
    const [authorized] = useAuthorized();
    const [items, setItems] = useState(menuItems);

    const alignCss =
        headerSettings?.headerLogoPosition === "logo-left"
            ? "align-right"
            : "align-left";
    const headerWide = headerSettings?.headerWide ?? true;
    const logoCss = themeInformation?.logoHeight ?? "";
    const showButtons = headerSettings?.showButtons ?? false;
    const menuBgType = headerSettings?.menuBackgroundType;
    // const menuBgColor = headerSettings?.menuBackgroundColor;
    let menuBgCls = "default";

    switch (menuBgType) {
    case "default":
        menuBgCls = "menu-dark";
        break;
    case "white":
        menuBgCls = "menu-light";
        break;
    case "custom":
        menuBgCls = "menu-custom";
        break;
    default:
        menuBgCls = "menu-dark";
        break;
    }

    let pathUrl = "";
    pathUrl = " ";

    const useOrbeeAuto = thirdPartyIntegration?.useOrbeeAuto ?? false;
    const idOrbeeAuto = thirdPartyIntegration?.idOrbeeAuto ?? '';
    const logoImage = themeInformation?.logoImage || "";

    const CreateMenu = (items: any) => {
        return items?.map((item: any, index: any) => {
            {
                const currentNavItem = item.data || {};
                const currentChildren = item.children || null;
                const currentTitle = currentNavItem.title;
                const currentLink = currentNavItem.link;
                const currentCustomLink =
                    currentLink == "custom" ? currentNavItem.customLink : "";
                const currentHRef =
                    currentChildren !== null && currentChildren.length > 0
                        ? "#"
                        : currentCustomLink || currentLink || "#";
                const currentCustomClass = currentNavItem.customClass || "";
                const currentShowLink = currentNavItem.showLink;
                const allowReload = ['locations', 'value-your-trade'].includes(currentHRef.replace('/', '').toLowerCase()) || currentCustomClass.includes('no-prefetch');

                if (currentShowLink) {
                    return (
                        <li
                            className={`${(Boolean(currentChildren.length > 0) && !isMobileDevice) ? "hoverTrigger" : ""} ${currentCustomClass}`}
                            key={index}
                            onClick={() => (currentChildren.length > 0 && !isMobileDevice) ? null 
                                : (currentChildren.length > 0 && isMobileDevice ? openSubMenu(item) : openMenuInMobile(false))
                            }
                        >
                            {allowReload
                                && <a href={currentHRef}>
                                    {currentTitle}
                                    {currentChildren.length > 0 && (
                                        <>
                                            <i className="fa fa-angle-down fa-indicator"></i>
                                            <div className="mobileTriggerButton"></div>
                                        </>
                                    )}
                                </a>}

                            {!allowReload
                                && <Link href={currentHRef} prefetch={false}>
                                    {currentTitle}
                                    {currentChildren.length > 0 && (
                                        <>
                                            <i className="fa fa-angle-down fa-indicator"></i>
                                            <div className="mobileTriggerButton"></div>
                                        </>
                                    )}                                
                                </Link>
                            }

                            {currentChildren?.length > 0 && (
                                <ul
                                    className={`drop-down-multilevel effect-fade effect-expand-left ${item.isOpen ? 'menu-is-open' : ''}`}
                                    style={{ transition: "all 400ms ease 0s" }}
                                >
                                    {CreateMenu(currentChildren)}
                                </ul>
                            )}
                        </li>
                    );
                }
            }
        });
    };

    const openSubMenu = (item: any) => {
        const newItems = items.map((c: any)=>{
            if(c.key===item.key) c.isOpen= !c.isOpen ?? true;
            else c.isOpen = false;

            return c;
        });
        setItems(newItems);
    };

    const openMenuInMobile = (forceClose = false) => {
        const elementMenu = document.getElementById("menu");
        if (elementMenu?.classList.contains("active") || forceClose) elementMenu?.classList.remove("active");
        else elementMenu?.classList.add("active");

        const element = document.getElementsByClassName("menu-mobile-collapse-trigger")?.[0];
        if (element?.classList.contains("active") || forceClose) element?.classList.remove("active");
        else element?.classList.add("active");

        const elementMenuLinks = document.getElementById("menu-links");
        if (elementMenuLinks?.classList.contains("active") || forceClose) elementMenuLinks?.classList.remove("active");
        else elementMenuLinks?.classList.add("active");

        if (isMobileDevice) {
            const bodyBehavior = document.body;
            if (bodyBehavior?.classList.contains("not-scrolling") || forceClose)
                bodyBehavior?.classList.remove("not-scrolling");
            else bodyBehavior?.classList.add("not-scrolling");
        }
    };

    const logoWidth = isMobileDevice ? 110 : 174;
    const logoHeight = isMobileDevice ? 35 : 55;
    const logoImg = logoImage ? cloudinaryLoaderImage({ src: logoImage, width: logoWidth }) : "images/logo-dark.png";

    return (
        <div className={`menu ${menuBgCls}`}>
            <nav id="menu" className="mega-menu desktopTopFixed mobileTopFixed">
                <section className="menu-list-items">
                    <div className={headerWide ? "container-fluid" : "container"}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 text-center">
                                {!isMobileDevice && showButtons && pathUrl !== "/admin" && (
                                    <ul
                                        className={`ax-list-button-header onlydesktop ${alignCss}`}
                                    >
                                        {CreateHeaderButtons()}
                                    </ul>
                                )}

                                <ul className="menu-logo">
                                    <li>
                                        <span className="onlymobile ax-letter-menu">MENU</span>
                                        <div className="menu-mobile-collapse-trigger"
                                            onClick={()=> openMenuInMobile(false)}
                                        >
                                            <span></span>
                                        </div>
                                        <Link href="/" prefetch={false}>
                                            <Image
                                                loader={defaultLoader}
                                                className={logoCss}
                                                id="logo_dark_img"
                                                src={logoImg}
                                                width={logoWidth}
                                                height={logoHeight}
                                                priority
                                                alt="logo"
                                                onClick={()=> openMenuInMobile(true)}
                                            />
                                        </Link>
                                        <div
                                            className="menu-filter-vehicles"
                                            onClick={openFilterInMobile}
                                        >
                                            <span className="ax-letter-filter">FILTERS</span>
                                            <span className="fa fa-sliders"></span>
                                        </div>
                                    </li>
                                </ul>
                                <ul
                                    id="menu-links"
                                    className="menu-links"
                                >
                                    <>
                                        {useOrbeeAuto && (
                                            <li>
                                                <a
                                                    href="/saved-favorites" 
                                                    dangerouslySetInnerHTML={{__html: `<orb data-plugin-id="${idOrbeeAuto}"
                                                        data-type="favorites-counter"
                                                        data-default="0"
                                                        data-size="13" />`}}
                                                >
                                                </a>
                                            </li>
                                        )}
                                        {CreateMenu(items)}
                                        {authorized &&
                                            <li>
                                                <Link href='/admin' prefetch={false}>
                                                    Admin
                                                </Link>
                                            </li>
                                        }
                                    </>
                                </ul>

                                {isMobileDevice && showButtons && pathUrl !== "/admin" && (
                                    <ul className="mobile-button-header onlymobile">
                                        {CreateHeaderButtons()}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </nav>
        </div>
    );
};

export default Navigation;
