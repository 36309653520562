import Navigation from "components/Navigation";
import { useRouter } from 'next/router';
import dynamic from "next/dynamic";

const HeaderBar = dynamic(() => import("components/Header/HeaderBar"));
const NotificationHeader = dynamic(() => import("components/NotificationHeader/NotificationHeader"));

const Header = ({ theme, navBar, thirdParty, home }: any) => {
    const router = useRouter();

    const headerSettings = home?.find((c: any) => c.sectionType === 'HeaderSection')?.value;
    const logoHeight = theme?.logoHeight || "largelogo";
    const showNotificationHeader = headerSettings?.showNotificationHeader ?? true;
    const headerLogoPosition = headerSettings?.headerLogoPosition ?? "logo-left";
    const headerBannerPosition = headerSettings?.headerBannerPosition ?? "top";
    const showHeaderBanner = headerSettings?.show ?? true;

    const pathUrl = router.pathname;

    return (
        <header id="header" className={`${headerLogoPosition} ${logoHeight}`}>
            {showHeaderBanner && pathUrl !== "/admin" && headerBannerPosition === "top"
                && <HeaderBar headerSettings={headerSettings} />
            }

            <Navigation
                menuItems={navBar}
                headerSettings={headerSettings}
                themeInformation={theme}
                thirdPartyIntegration={thirdParty}
            />

            {showHeaderBanner && pathUrl !== "/admin"
                && headerBannerPosition === "bottom"
                && <HeaderBar headerSettings={headerSettings} />
            }

            {showNotificationHeader && pathUrl !== "/admin"
                && <NotificationHeader headerSettings={headerSettings} />
            }
        </header>
    );
};

export default Header;
